import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  GenericScalar: any;
  JSONString: any;
  UploadType: any;
  UUID: any;
};

export type ConnectionCreateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The kind of the connection. */
  kind: ConnectionKind;
  /** The sheet's name. */
  name?: InputMaybe<Scalars['String']>;
  /** The upload for upload type. */
  upload?: InputMaybe<Scalars['UploadType']>;
  /** The connection's original url. */
  url?: InputMaybe<Scalars['String']>;
  /** The sheet's owner. */
  user?: InputMaybe<Scalars['ID']>;
};

/** Create a new connection. */
export type ConnectionCreateMutationPayload = {
  __typename?: 'ConnectionCreateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  connection?: Maybe<ConnectionType>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
};

export type ConnectionDeleteMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** Delete a connection from the database. */
export type ConnectionDeleteMutationPayload = {
  __typename?: 'ConnectionDeleteMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  connection?: Maybe<ConnectionType>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
};

/** An enumeration. */
export enum ConnectionHitMethod {
  /** DELETE */
  Delete = 'DELETE',
  /** GET */
  Get = 'GET',
  /** PATCH */
  Patch = 'PATCH',
  /** POST */
  Post = 'POST',
  /** PUT */
  Put = 'PUT',
}

/** Connection hit by period. */
export type ConnectionHitsByPeriodType = {
  __typename?: 'ConnectionHitsByPeriodType';
  /** Total hits in the period. */
  hits: Scalars['Int'];
  /** Hits in the method (if specified). */
  method?: Maybe<Scalars['String']>;
  /** The period asked for. */
  period?: Maybe<Scalars['String']>;
};

/** Connection hit type. */
export type ConnectionHitType = Node & {
  __typename?: 'ConnectionHitType';
  connection?: Maybe<ConnectionType>;
  /** When this model was created. */
  createdAt: Scalars['DateTime'];
  /** The data sent in the request. */
  data: Scalars['JSONString'];
  drive?: Maybe<DriveType>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The ip that made this request. */
  ipAddress: Scalars['String'];
  /** The kind of the connection. */
  kind?: Maybe<ConnectionKind>;
  /** The HTTP request method. */
  method: ConnectionHitMethod;
  /** The params sent in the request. */
  params: Scalars['JSONString'];
  /** The HTTP request path. */
  path: Scalars['String'];
  sheet?: Maybe<SheetType>;
  /** If the allowed ips list is enabled. */
  source: ConnectionUnion;
  /** When this model was last updated. */
  updatedAt: Scalars['DateTime'];
  upload?: Maybe<SheetUploadType>;
  user: UserType;
};

export type ConnectionHitTypeConnection = {
  __typename?: 'ConnectionHitTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConnectionHitTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ConnectionHitType` and its cursor. */
export type ConnectionHitTypeEdge = {
  __typename?: 'ConnectionHitTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ConnectionHitType>;
};

export type ConnectionInterface = {
  /** List of allowed ips for this connection. */
  allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** If the allowed ips list is enabled. */
  allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
  /** List of allowed methods for this connection. */
  allowedMethods?: Maybe<Array<Scalars['String']>>;
  /** List of allowed origins for this conenction. */
  allowedOrigins?: Maybe<Array<Scalars['String']>>;
  /** If the allowed origins list is enabled. */
  allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
  /** The api key. */
  apiKey?: Maybe<Scalars['String']>;
  /** If the api key is enabled. */
  apiKeyEnabled?: Maybe<Scalars['Boolean']>;
  /** The connection's api url. */
  apiUrl: Scalars['String'];
  /** When this connection was created. */
  createdAt: Scalars['DateTime'];
  /** Total hits this month in the connection's api. */
  hitsCached: Scalars['Int'];
  /** Total lifetime hits in the connection's api. */
  hitsCachedTotal: Scalars['Int'];
  /** The kind of the result. */
  kind: ConnectionKind;
  /** TWhen this connection was last hitted. */
  lastHittedAt?: Maybe<Scalars['DateTime']>;
  /** This connection's hits limit. */
  limit: Scalars['Int'];
  /** The connection's name. */
  name?: Maybe<Scalars['String']>;
  /** Original connection's id as UUID */
  pk: Scalars['UUID'];
  /** This connection's remaining hits. */
  remaining: Scalars['Int'];
  /** This connection's remaining hits. */
  starred?: Maybe<Scalars['Boolean']>;
  /** Total rows in this connection. */
  totalRows?: Maybe<Scalars['Int']>;
  /** For how longs the response in this connection is cached. */
  ttl: Scalars['Int'];
  /** When this connection was updated. */
  updatedAt: Scalars['DateTime'];
};

/** Connection kind. */
export enum ConnectionKind {
  Gdrive = 'GDRIVE',
  Gsheet = 'GSHEET',
  Upload = 'UPLOAD',
}

/** Connection type. */
export type ConnectionType = ConnectionInterface &
  Node & {
    __typename?: 'ConnectionType';
    /** List of allowed ips for this connection. */
    allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** If the allowed ips list is enabled. */
    allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed methods for this connection. */
    allowedMethods?: Maybe<Array<Scalars['String']>>;
    /** List of allowed origins for this conenction. */
    allowedOrigins?: Maybe<Array<Scalars['String']>>;
    /** If the allowed origins list is enabled. */
    allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
    /** The api key. */
    apiKey?: Maybe<Scalars['String']>;
    /** If the api key is enabled. */
    apiKeyEnabled?: Maybe<Scalars['Boolean']>;
    /** The connection's api url. */
    apiUrl: Scalars['String'];
    /** When this connection was created. */
    createdAt: Scalars['DateTime'];
    /** Total hits this month in the connection's api. */
    hitsCached: Scalars['Int'];
    /** Total lifetime hits in the connection's api. */
    hitsCachedTotal: Scalars['Int'];
    /** The ID of the object. */
    id: Scalars['ID'];
    /** The kind of the result. */
    kind: ConnectionKind;
    /** TWhen this connection was last hitted. */
    lastHittedAt?: Maybe<Scalars['DateTime']>;
    /** This connection's hits limit. */
    limit: Scalars['Int'];
    /** The connection's name. */
    name?: Maybe<Scalars['String']>;
    /** Original connection's id as UUID */
    pk: Scalars['UUID'];
    /** This connection's remaining hits. */
    remaining: Scalars['Int'];
    /** If the allowed ips list is enabled. */
    source: ConnectionUnion;
    /** This connection's remaining hits. */
    starred?: Maybe<Scalars['Boolean']>;
    /** Total rows in this connection. */
    totalRows?: Maybe<Scalars['Int']>;
    /** For how longs the response in this connection is cached. */
    ttl: Scalars['Int'];
    /** When this connection was updated. */
    updatedAt: Scalars['DateTime'];
    /** The connection's original url. */
    url?: Maybe<Scalars['String']>;
  };

export type ConnectionTypeConnection = {
  __typename?: 'ConnectionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ConnectionType` and its cursor. */
export type ConnectionTypeEdge = {
  __typename?: 'ConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ConnectionType>;
};

/** Connection union. */
export type ConnectionUnion = DriveType | SheetType | SheetUploadType;

export type ConnectionUpdateMutationInput = {
  /** List of ips allowed for this sheet. */
  allowedIps?: InputMaybe<Array<Scalars['String']>>;
  /** If the allowed ips list are enabled. */
  allowedIpsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** List of methods allowed for this sheet. */
  allowedMethods?: InputMaybe<Array<Scalars['String']>>;
  /** List of origins allowed for this sheet. */
  allowedOrigins?: InputMaybe<Array<Scalars['String']>>;
  /** If the allowed origins list are enabled. */
  allowedOriginsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** A key to protect this sheet's endpoint. */
  apiKey?: InputMaybe<Scalars['String']>;
  /** If the api key is enabled. */
  apiKeyEnabled?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The sheet's name. */
  name?: InputMaybe<Scalars['String']>;
  /** If the api is starred. */
  starred?: InputMaybe<Scalars['Boolean']>;
  /** For how long should we cache the content (in seconds). */
  ttl?: InputMaybe<Scalars['Int']>;
};

/** Update a connection in the database. */
export type ConnectionUpdateMutationPayload = {
  __typename?: 'ConnectionUpdateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  connection?: Maybe<ConnectionType>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
};

export type ConnectionValidateUrlMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The kind of the URL to validate. */
  kind: ConnectionKind;
  /** The connection URL to validate. */
  url: Scalars['String'];
};

/** Validate that the connection URL is valid. */
export type ConnectionValidateUrlMutationPayload = {
  __typename?: 'ConnectionValidateUrlMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the url is valid or not. */
  isValid?: Maybe<Scalars['Boolean']>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug';
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
};

/** Drive type. */
export type DriveType = ConnectionInterface &
  Node & {
    __typename?: 'DriveType';
    /** List of allowed ips for this connection. */
    allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** If the allowed ips list is enabled. */
    allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed methods for this connection. */
    allowedMethods?: Maybe<Array<Scalars['String']>>;
    /** List of allowed origins for this conenction. */
    allowedOrigins?: Maybe<Array<Scalars['String']>>;
    /** If the allowed origins list is enabled. */
    allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
    /** The api key. */
    apiKey?: Maybe<Scalars['String']>;
    /** If the api key is enabled. */
    apiKeyEnabled?: Maybe<Scalars['Boolean']>;
    /** The connection's api url. */
    apiUrl: Scalars['String'];
    /** When this connection was created. */
    createdAt: Scalars['DateTime'];
    /** Total hits this month in the connection's api. */
    hitsCached: Scalars['Int'];
    /** Total lifetime hits in the connection's api. */
    hitsCachedTotal: Scalars['Int'];
    /** The ID of the object. */
    id: Scalars['ID'];
    /** The kind of the result. */
    kind: ConnectionKind;
    /** TWhen this connection was last hitted. */
    lastHittedAt?: Maybe<Scalars['DateTime']>;
    /** This connection's hits limit. */
    limit: Scalars['Int'];
    /** The connection's name. */
    name?: Maybe<Scalars['String']>;
    /** Original connection's id as UUID */
    pk: Scalars['UUID'];
    /** This connection's remaining hits. */
    remaining: Scalars['Int'];
    /** This connection's remaining hits. */
    starred?: Maybe<Scalars['Boolean']>;
    /** Total rows in this connection. */
    totalRows?: Maybe<Scalars['Int']>;
    /** For how longs the response in this connection is cached. */
    ttl: Scalars['Int'];
    /** When this connection was updated. */
    updatedAt: Scalars['DateTime'];
    /** The drive's url. */
    url: Scalars['String'];
  };

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a new connection. */
  connectionCreate?: Maybe<ConnectionCreateMutationPayload>;
  /** Delete a connection from the database. */
  connectionDelete?: Maybe<ConnectionDeleteMutationPayload>;
  /** Update a connection in the database. */
  connectionUpdate?: Maybe<ConnectionUpdateMutationPayload>;
  /** Validate that the connection URL is valid. */
  connectionValidateUrl?: Maybe<ConnectionValidateUrlMutationPayload>;
  node?: Maybe<Node>;
  /** Create a new sheet. */
  sheetCreate?: Maybe<SheetCreateMutationPayload>;
  /** Delete the sheet from the database. */
  sheetDelete?: Maybe<SheetDeleteMutationPayload>;
  /** Toggle the starred state of the sheety. */
  sheetStarToggle?: Maybe<SheetStarToggleMutationPayload>;
  /** Update a sheet in the database. */
  sheetUpdate?: Maybe<SheetUpdateMutationPayload>;
  /** Validate that the sheet URL is valid. */
  sheetValidateUrl?: Maybe<SheetValidateUrlMutationPayload>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebTokenPayload>;
  tokenRefresh?: Maybe<RefreshPayload>;
  tokenVerify?: Maybe<VerifyPayload>;
  /** Activate a user's registration. */
  userActivate?: Maybe<UserActivateMutationPayload>;
  /** Create a new user. */
  userCreate?: Maybe<UserCreateMutationPayload>;
  /** Deactivate a user. */
  userDeactivate?: Maybe<UserDeactivateMutationPayload>;
  /** Mutation that logs the user out. */
  userLogout?: Maybe<UserLogoutMutationPayload>;
  /** Confirm the request to reset a user's password. */
  userPasswordConfirm?: Maybe<UserResetPasswordConfirmMutationPayload>;
  /** Request to reset a user's password. */
  userResetPassword?: Maybe<UserResetPasswordMutationPayload>;
  /** Stripe subscription session creat for user. */
  userStripeCheckoutSessionCreate?: Maybe<UserStripeCheckoutSessionCreateMutationPayload>;
  /** Stripe subscription session creat for failed users. */
  userStripeSubscriptionCheckoutSessionCreate?: Maybe<
    UserStripeSubscriptionCheckoutSessionCreateMutationPayload
  >;
  /** Subscribe the user to the given subscription. */
  userSubscriptionChange?: Maybe<UserSubscriptionChangeMutationPayload>;
  /** Confirm the subscription change for the user. */
  userSubscriptionChangeConfirm?: Maybe<UserSubscriptionChangeConfirmMutationPayload>;
  /** Subscribe the user to the given subscription. */
  userSubscriptionCreate?: Maybe<UserSubscriptionCreateMutationPayload>;
};

export type MutationConnectionCreateArgs = {
  input: ConnectionCreateMutationInput;
};

export type MutationConnectionDeleteArgs = {
  input: ConnectionDeleteMutationInput;
};

export type MutationConnectionUpdateArgs = {
  input: ConnectionUpdateMutationInput;
};

export type MutationConnectionValidateUrlArgs = {
  input: ConnectionValidateUrlMutationInput;
};

export type MutationNodeArgs = {
  id: Scalars['ID'];
};

export type MutationSheetCreateArgs = {
  input: SheetCreateMutationInput;
};

export type MutationSheetDeleteArgs = {
  input: SheetDeleteMutationInput;
};

export type MutationSheetStarToggleArgs = {
  input: SheetStarToggleMutationInput;
};

export type MutationSheetUpdateArgs = {
  input: SheetUpdateMutationInput;
};

export type MutationSheetValidateUrlArgs = {
  input: SheetValidateUrlMutationInput;
};

export type MutationTokenAuthArgs = {
  input: ObtainJsonWebTokenInput;
};

export type MutationTokenRefreshArgs = {
  input: RefreshInput;
};

export type MutationTokenVerifyArgs = {
  input: VerifyInput;
};

export type MutationUserActivateArgs = {
  input: UserActivateMutationInput;
};

export type MutationUserCreateArgs = {
  input: UserCreateMutationInput;
};

export type MutationUserDeactivateArgs = {
  input: UserDeactivateMutationInput;
};

export type MutationUserLogoutArgs = {
  input: UserLogoutMutationInput;
};

export type MutationUserPasswordConfirmArgs = {
  input: UserResetPasswordConfirmMutationInput;
};

export type MutationUserResetPasswordArgs = {
  input: UserResetPasswordMutationInput;
};

export type MutationUserStripeCheckoutSessionCreateArgs = {
  input: UserStripeCheckoutSessionCreateMutationInput;
};

export type MutationUserStripeSubscriptionCheckoutSessionCreateArgs = {
  input: UserStripeSubscriptionCheckoutSessionCreateMutationInput;
};

export type MutationUserSubscriptionChangeArgs = {
  input: UserSubscriptionChangeMutationInput;
};

export type MutationUserSubscriptionChangeConfirmArgs = {
  input: UserSubscriptionChangeConfirmMutationInput;
};

export type MutationUserSubscriptionCreateArgs = {
  input: UserSubscriptionCreateMutationInput;
};

/** An error that happened in a mutation. */
export type MutationErrorType = {
  __typename?: 'MutationErrorType';
  /** The field that caused the error, or `null` if it isn't associated with any particular field. */
  field?: Maybe<Scalars['String']>;
  /** The error message. */
  message?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type ObtainJsonWebTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebTokenPayload = {
  __typename?: 'ObtainJSONWebTokenPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Period type for filtering. */
export enum PeriodType {
  Day = 'DAY',
  Hour = 'HOUR',
  Weekday = 'WEEKDAY',
  Whole = 'WHOLE',
}

export type Query = {
  __typename?: 'Query';
  _debug?: Maybe<DjangoDebug>;
  /** A connection in the database. */
  connection?: Maybe<ConnectionType>;
  /** All sheet hits in the database. */
  connectionHits?: Maybe<ConnectionHitTypeConnection>;
  /** Hits in the sheet by a given period. */
  connectionHitsByPeriod?: Maybe<Array<ConnectionHitsByPeriodType>>;
  /** All sheet in the database. */
  connections?: Maybe<ConnectionTypeConnection>;
  /** The currently logged user. */
  me?: Maybe<UserType>;
  /** A sheet in the database. */
  sheet?: Maybe<SheetType>;
  /** All sheet hits in the database. */
  sheetHits?: Maybe<SheetHitTypeConnection>;
  /** Hits in the sheet by a given period. */
  sheetHitsByPeriod?: Maybe<Array<ConnectionHitsByPeriodType>>;
  /** A public sheet in the database. */
  sheetPublic?: Maybe<SheetPublicType>;
  /** All sheet in the database. */
  sheets?: Maybe<SheetTypeConnection>;
  /** A subscription plan given its ID. */
  subscriptionPlan?: Maybe<SubscriptionPlanType>;
  /** All public subscription plans. */
  subscriptionPlans?: Maybe<Array<SubscriptionPlanType>>;
};

export type QueryConnectionArgs = {
  id: Scalars['ID'];
};

export type QueryConnectionHitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  connection?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_Gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_Lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_Range?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  method_Iexact?: InputMaybe<Scalars['String']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  path_Icontains?: InputMaybe<Scalars['String']>;
  path_Iexact?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type QueryConnectionHitsByPeriodArgs = {
  connection?: InputMaybe<Scalars['ID']>;
  end: Scalars['Date'];
  groupByMethod?: InputMaybe<Scalars['Boolean']>;
  periodType: PeriodType;
  start: Scalars['Date'];
  tz?: InputMaybe<Scalars['String']>;
};

export type QueryConnectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  name_Iexact?: InputMaybe<Scalars['String']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  starred?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySheetArgs = {
  id: Scalars['ID'];
};

export type QuerySheetHitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  connection?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAt_Gt?: InputMaybe<Scalars['DateTime']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']>;
  createdAt_Lt?: InputMaybe<Scalars['DateTime']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']>;
  createdAt_Range?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  method_Iexact?: InputMaybe<Scalars['String']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  path_Iexact?: InputMaybe<Scalars['String']>;
  path_Istartswith?: InputMaybe<Scalars['String']>;
};

export type QuerySheetHitsByPeriodArgs = {
  end: Scalars['Date'];
  groupByMethod?: InputMaybe<Scalars['Boolean']>;
  periodType: PeriodType;
  sheet?: InputMaybe<Scalars['ID']>;
  start: Scalars['Date'];
  tz?: InputMaybe<Scalars['String']>;
};

export type QuerySheetPublicArgs = {
  id: Scalars['UUID'];
};

export type QuerySheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  starred?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['String']>;
};

export type QuerySubscriptionPlanArgs = {
  id: Scalars['ID'];
};

export type RefreshInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type RefreshPayload = {
  __typename?: 'RefreshPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type SheetCreateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The sheet's name. */
  name?: InputMaybe<Scalars['String']>;
  /** The sheet's url. */
  url: Scalars['String'];
  /** The sheet's owner. */
  user?: InputMaybe<Scalars['ID']>;
};

/** Create a new sheet. */
export type SheetCreateMutationPayload = {
  __typename?: 'SheetCreateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  sheet?: Maybe<SheetType>;
};

export type SheetDeleteMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** Delete the sheet from the database. */
export type SheetDeleteMutationPayload = {
  __typename?: 'SheetDeleteMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  sheet?: Maybe<SheetType>;
};

/** An enumeration. */
export enum SheetHitMethod {
  /** DELETE */
  Delete = 'DELETE',
  /** GET */
  Get = 'GET',
  /** PATCH */
  Patch = 'PATCH',
  /** POST */
  Post = 'POST',
  /** PUT */
  Put = 'PUT',
}

/** Sheet hit type. */
export type SheetHitType = Node & {
  __typename?: 'SheetHitType';
  /** The sheet related to this hit. */
  connection: SheetType;
  /** When this model was created. */
  createdAt: Scalars['DateTime'];
  /** The data sent in the request. */
  data: Scalars['JSONString'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The ip that made this request. */
  ipAddress: Scalars['String'];
  /** The HTTP request method. */
  method: SheetHitMethod;
  /** The params sent in the request. */
  params: Scalars['JSONString'];
  /** The HTTP request path. */
  path: Scalars['String'];
  /** When this model was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type SheetHitTypeConnection = {
  __typename?: 'SheetHitTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SheetHitTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SheetHitType` and its cursor. */
export type SheetHitTypeEdge = {
  __typename?: 'SheetHitTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SheetHitType>;
};

/** Sheet type for public viewing. */
export type SheetPublicType = ConnectionInterface & {
  __typename?: 'SheetPublicType';
  /** List of allowed ips for this connection. */
  allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** If the allowed ips list is enabled. */
  allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
  /** List of allowed methods for this connection. */
  allowedMethods?: Maybe<Array<Scalars['String']>>;
  /** List of allowed origins for this conenction. */
  allowedOrigins?: Maybe<Array<Scalars['String']>>;
  /** If the allowed origins list is enabled. */
  allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
  /** The api key. */
  apiKey?: Maybe<Scalars['String']>;
  /** If the api key is enabled. */
  apiKeyEnabled?: Maybe<Scalars['Boolean']>;
  /** The connection's api url. */
  apiUrl: Scalars['String'];
  /** When this connection was created. */
  createdAt: Scalars['DateTime'];
  /** Total hits this month in the connection's api. */
  hitsCached: Scalars['Int'];
  /** Total lifetime hits in the connection's api. */
  hitsCachedTotal: Scalars['Int'];
  /** The sheet's id. */
  id?: Maybe<Scalars['UUID']>;
  /** The kind of the result. */
  kind: ConnectionKind;
  /** TWhen this connection was last hitted. */
  lastHittedAt?: Maybe<Scalars['DateTime']>;
  /** This connection's hits limit. */
  limit: Scalars['Int'];
  /** The connection's name. */
  name?: Maybe<Scalars['String']>;
  /** Original connection's id as UUID */
  pk: Scalars['UUID'];
  /** This connection's remaining hits. */
  remaining: Scalars['Int'];
  /** This connection's remaining hits. */
  starred?: Maybe<Scalars['Boolean']>;
  /** Total rows in this connection. */
  totalRows?: Maybe<Scalars['Int']>;
  /** For how longs the response in this connection is cached. */
  ttl: Scalars['Int'];
  /** When this connection was updated. */
  updatedAt: Scalars['DateTime'];
  /** The sheet's url. */
  url?: Maybe<Scalars['String']>;
};

export type SheetStarToggleMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** Toggle the starred state of the sheety. */
export type SheetStarToggleMutationPayload = {
  __typename?: 'SheetStarToggleMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  sheet?: Maybe<SheetType>;
};

/** Sheet type. */
export type SheetType = ConnectionInterface &
  Node & {
    __typename?: 'SheetType';
    /** List of allowed ips for this connection. */
    allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** If the allowed ips list is enabled. */
    allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed methods for this connection. */
    allowedMethods?: Maybe<Array<Scalars['String']>>;
    /** List of allowed origins for this conenction. */
    allowedOrigins?: Maybe<Array<Scalars['String']>>;
    /** If the allowed origins list is enabled. */
    allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
    /** The api key. */
    apiKey?: Maybe<Scalars['String']>;
    /** If the api key is enabled. */
    apiKeyEnabled?: Maybe<Scalars['Boolean']>;
    /** The connection's api url. */
    apiUrl: Scalars['String'];
    /** When this connection was created. */
    createdAt: Scalars['DateTime'];
    /** Total hits this month in the connection's api. */
    hitsCached: Scalars['Int'];
    /** Total lifetime hits in the connection's api. */
    hitsCachedTotal: Scalars['Int'];
    /** The ID of the object. */
    id: Scalars['ID'];
    /** The kind of the result. */
    kind: ConnectionKind;
    /** TWhen this connection was last hitted. */
    lastHittedAt?: Maybe<Scalars['DateTime']>;
    /** This connection's hits limit. */
    limit: Scalars['Int'];
    /** The connection's name. */
    name?: Maybe<Scalars['String']>;
    /** Original connection's id as UUID */
    pk: Scalars['UUID'];
    /** This connection's remaining hits. */
    remaining: Scalars['Int'];
    /** This connection's remaining hits. */
    starred?: Maybe<Scalars['Boolean']>;
    /** Total rows in this connection. */
    totalRows?: Maybe<Scalars['Int']>;
    /** For how longs the response in this connection is cached. */
    ttl: Scalars['Int'];
    /** When this connection was updated. */
    updatedAt: Scalars['DateTime'];
    /** The sheet's url. */
    url: Scalars['String'];
  };

export type SheetTypeConnection = {
  __typename?: 'SheetTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SheetTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SheetType` and its cursor. */
export type SheetTypeEdge = {
  __typename?: 'SheetTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SheetType>;
};

export type SheetUpdateMutationInput = {
  /** List of ips allowed for this sheet. */
  allowedIps?: InputMaybe<Array<Scalars['String']>>;
  /** If the allowed ips list are enabled. */
  allowedIpsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** List of methods allowed for this sheet. */
  allowedMethods?: InputMaybe<Array<Scalars['String']>>;
  /** List of origins allowed for this sheet. */
  allowedOrigins?: InputMaybe<Array<Scalars['String']>>;
  /** If the allowed origins list are enabled. */
  allowedOriginsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** A key to protect this sheet's endpoint. */
  apiKey?: InputMaybe<Scalars['String']>;
  /** If the api key is enabled. */
  apiKeyEnabled?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The sheet's name. */
  name?: InputMaybe<Scalars['String']>;
  /** If the api is starred. */
  starred?: InputMaybe<Scalars['Boolean']>;
  /** For how long should we cache the content (in seconds). */
  ttl?: InputMaybe<Scalars['Int']>;
};

/** Update a sheet in the database. */
export type SheetUpdateMutationPayload = {
  __typename?: 'SheetUpdateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  sheet?: Maybe<SheetType>;
};

/** Upload type. */
export type SheetUploadType = ConnectionInterface &
  Node & {
    __typename?: 'SheetUploadType';
    /** List of allowed ips for this connection. */
    allowedIps?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** If the allowed ips list is enabled. */
    allowedIpsEnabled?: Maybe<Scalars['Boolean']>;
    /** List of allowed methods for this connection. */
    allowedMethods?: Maybe<Array<Scalars['String']>>;
    /** List of allowed origins for this conenction. */
    allowedOrigins?: Maybe<Array<Scalars['String']>>;
    /** If the allowed origins list is enabled. */
    allowedOriginsEnabled?: Maybe<Scalars['Boolean']>;
    /** The api key. */
    apiKey?: Maybe<Scalars['String']>;
    /** If the api key is enabled. */
    apiKeyEnabled?: Maybe<Scalars['Boolean']>;
    /** The connection's api url. */
    apiUrl: Scalars['String'];
    /** When this connection was created. */
    createdAt: Scalars['DateTime'];
    /** Total hits this month in the connection's api. */
    hitsCached: Scalars['Int'];
    /** Total lifetime hits in the connection's api. */
    hitsCachedTotal: Scalars['Int'];
    /** The ID of the object. */
    id: Scalars['ID'];
    /** The kind of the result. */
    kind: ConnectionKind;
    /** TWhen this connection was last hitted. */
    lastHittedAt?: Maybe<Scalars['DateTime']>;
    /** This connection's hits limit. */
    limit: Scalars['Int'];
    /** The connection's name. */
    name?: Maybe<Scalars['String']>;
    /** Original connection's id as UUID */
    pk: Scalars['UUID'];
    /** This connection's remaining hits. */
    remaining: Scalars['Int'];
    /** This connection's remaining hits. */
    starred?: Maybe<Scalars['Boolean']>;
    /** Total rows in this connection. */
    totalRows?: Maybe<Scalars['Int']>;
    /** For how longs the response in this connection is cached. */
    ttl: Scalars['Int'];
    /** When this connection was updated. */
    updatedAt: Scalars['DateTime'];
  };

export type SheetValidateUrlMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The sheet URL to validate. */
  url: Scalars['String'];
};

/** Validate that the sheet URL is valid. */
export type SheetValidateUrlMutationPayload = {
  __typename?: 'SheetValidateUrlMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the url is valid or not. */
  isValid?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum SubscriptionPlanChartsTier {
  /** Advanced */
  Advanced = 'ADVANCED',
  /** Simple */
  Simple = 'SIMPLE',
}

/** An enumeration. */
export enum SubscriptionPlanLogRetention {
  /** 1 Month */
  A_1Month = 'A_1MONTH',
  /** 1 Year */
  A_1Year = 'A_1YEAR',
  /** Forever */
  Forever = 'FOREVER',
}

/** Subscription plan type. */
export type SubscriptionPlanType = Node & {
  __typename?: 'SubscriptionPlanType';
  /** Does this plan have advanced queries. */
  advancedQueries: Scalars['Boolean'];
  /** Does this plan have api keys. */
  apiKey: Scalars['Boolean'];
  /** Does this plan have renewable api keys. */
  apiKeyRenewable: Scalars['Boolean'];
  /** Chart tier. */
  chartsTier?: Maybe<SubscriptionPlanChartsTier>;
  /** The plan's code. */
  code: Scalars['String'];
  /** List of connections allowed for this subscription. */
  connectionsAllowed: Array<Scalars['String']>;
  /** Does this plan have custumizable cache. */
  customizableCache: Scalars['Boolean'];
  /** The description parts. */
  description: Array<Scalars['String']>;
  /** Is this plan has a trial period. */
  hasTrial: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Log retention. */
  logRetention?: Maybe<SubscriptionPlanLogRetention>;
  /** Maximum number of connections allowed in the plan. */
  maxConnections: Scalars['Int'];
  /** Maximum number of requests allowed in the plan. */
  maxRequests: Scalars['Int'];
  /** Does this plan have methods change support. */
  methodsSupport: Scalars['Boolean'];
  /** Is this the most popular plan. */
  mostPopular: Scalars['Boolean'];
  /** This plan's name. */
  name: Scalars['String'];
  /** The next tier for this subscription. */
  nextTier?: Maybe<SubscriptionPlanType>;
  /** This plan's paypal id. */
  paypalPlanId?: Maybe<Scalars['String']>;
  /** The plan's price. */
  price: Scalars['Float'];
  /** Does this plan have prioritary support. */
  prioritarySupport: Scalars['Boolean'];
  /** Is this plan open for anyone to subscribe to. */
  public: Scalars['Boolean'];
  /** Stripe's product id. */
  stripeProductId?: Maybe<Scalars['String']>;
  /** Does this plan have whitelist change support. */
  whitelistSupport: Scalars['Boolean'];
};

export type UserActivateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  uid: Scalars['String'];
};

/** Activate a user's registration. */
export type UserActivateMutationPayload = {
  __typename?: 'UserActivateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
};

export type UserCreateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  rePassword: Scalars['String'];
  username: Scalars['String'];
};

/** Create a new user. */
export type UserCreateMutationPayload = {
  __typename?: 'UserCreateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
};

export type UserDeactivateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  uid: Scalars['String'];
};

/** Deactivate a user. */
export type UserDeactivateMutationPayload = {
  __typename?: 'UserDeactivateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
};

export type UserLogoutMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutation that logs the user out. */
export type UserLogoutMutationPayload = {
  __typename?: 'UserLogoutMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
};

export type UserResetPasswordConfirmMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  rePassword: Scalars['String'];
  token: Scalars['String'];
  uid: Scalars['String'];
};

/** Confirm the request to reset a user's password. */
export type UserResetPasswordConfirmMutationPayload = {
  __typename?: 'UserResetPasswordConfirmMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
};

export type UserResetPasswordMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Request to reset a user's password. */
export type UserResetPasswordMutationPayload = {
  __typename?: 'UserResetPasswordMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
};

export type UserStripeCheckoutSessionCreateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The subscription plan ID. */
  planId: Scalars['String'];
};

/** Stripe subscription session creat for user. */
export type UserStripeCheckoutSessionCreateMutationPayload = {
  __typename?: 'UserStripeCheckoutSessionCreateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** Stripe checkout session id. */
  sessionId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserStripeSubscriptionCheckoutSessionCreateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The subscription plan ID. */
  subscriptionId: Scalars['String'];
};

/** Stripe subscription session creat for failed users. */
export type UserStripeSubscriptionCheckoutSessionCreateMutationPayload = {
  __typename?: 'UserStripeSubscriptionCheckoutSessionCreateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** Stripe checkout session id. */
  sessionId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserSubscriptionChangeConfirmMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** Confirm the subscription change for the user. */
export type UserSubscriptionChangeConfirmMutationPayload = {
  __typename?: 'UserSubscriptionChangeConfirmMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserSubscriptionChangeMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The new paypal plan ID. */
  subscriptionPlan: Scalars['ID'];
};

/** Subscribe the user to the given subscription. */
export type UserSubscriptionChangeMutationPayload = {
  __typename?: 'UserSubscriptionChangeMutationPayload';
  /** An approval link. */
  approvalLink?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the user needs to approve this change. */
  needApproval?: Maybe<Scalars['Boolean']>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

export type UserSubscriptionCreateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** The paypal subscription ID. */
  paypalSubscriptionId: Scalars['String'];
};

/** Subscribe the user to the given subscription. */
export type UserSubscriptionCreateMutationPayload = {
  __typename?: 'UserSubscriptionCreateMutationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

/** A user in the database. */
export type UserType = Node & {
  __typename?: 'UserType';
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** This user's encrypted hash. */
  hash?: Maybe<Scalars['String']>;
  /** How many hits this user sheets had (cached). */
  hitsCached: Scalars['Int'];
  /** How many hits this user sheets had in their lifetime (cached). */
  hitsCachedTotal: Scalars['Int'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  /** This user's hits limit. */
  limit?: Maybe<Scalars['Int']>;
  /** Original user's id as UUID */
  pk?: Maybe<Scalars['UUID']>;
  /**
   * This user's plan (old).
   * @deprecated Use subscription.code instead
   */
  plan?: Maybe<Scalars['String']>;
  /** This user's remaining hits. */
  remaining?: Maybe<Scalars['Int']>;
  /** This user's scopes */
  scopes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Stripe customer id for user. */
  stripeCustomerId?: Maybe<Scalars['String']>;
  /** Stripe's subscription id for new plans. */
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  /** Currently subscribed plan. */
  subscription: SubscriptionPlanType;
  /** Stripe Subscription Failure Flag. */
  subscriptionFailureFlag: Scalars['Boolean'];
  /** This user's token */
  token?: Maybe<Scalars['String']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
};

export type VerifyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type VerifyPayload = {
  __typename?: 'VerifyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  payload: Scalars['GenericScalar'];
};

export type SubscriptionFieldsFragment = {
  __typename?: 'SubscriptionPlanType';
  id: string;
  code: string;
  name: string;
  description: Array<string>;
  price: number;
  maxConnections: number;
  maxRequests: number;
  logRetention?: SubscriptionPlanLogRetention | null;
  chartsTier?: SubscriptionPlanChartsTier | null;
  connectionsAllowed: Array<string>;
  methodsSupport: boolean;
  whitelistSupport: boolean;
  prioritarySupport: boolean;
  advancedQueries: boolean;
  apiKey: boolean;
  apiKeyRenewable: boolean;
  customizableCache: boolean;
  paypalPlanId?: string | null;
  stripeProductId?: string | null;
  mostPopular: boolean;
  hasTrial: boolean;
  nextTier?: { __typename?: 'SubscriptionPlanType'; id: string } | null;
};

export type MeFieldsFragment = {
  __typename?: 'UserType';
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  dateJoined: any;
  id: string;
  hitsCached: number;
  hitsCachedTotal: number;
  stripeSubscriptionId?: string | null;
  stripeCustomerId?: string | null;
  subscriptionFailureFlag: boolean;
  pk?: any | null;
  limit?: number | null;
  remaining?: number | null;
  hash?: string | null;
  lastLogin?: any | null;
  subscription: {
    __typename?: 'SubscriptionPlanType';
    id: string;
    code: string;
    name: string;
    description: Array<string>;
    price: number;
    maxConnections: number;
    maxRequests: number;
    logRetention?: SubscriptionPlanLogRetention | null;
    chartsTier?: SubscriptionPlanChartsTier | null;
    connectionsAllowed: Array<string>;
    methodsSupport: boolean;
    whitelistSupport: boolean;
    prioritarySupport: boolean;
    advancedQueries: boolean;
    apiKey: boolean;
    apiKeyRenewable: boolean;
    customizableCache: boolean;
    paypalPlanId?: string | null;
    stripeProductId?: string | null;
    mostPopular: boolean;
    hasTrial: boolean;
    nextTier?: { __typename?: 'SubscriptionPlanType'; id: string } | null;
  };
};

export type AuthQueryVariables = Exact<{ [key: string]: never }>;

export type AuthQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    id: string;
    token?: string | null;
    scopes?: Array<string | null> | null;
    stripeSubscriptionId?: string | null;
    stripeCustomerId?: string | null;
    subscription: {
      __typename?: 'SubscriptionPlanType';
      id: string;
      code: string;
      name: string;
      description: Array<string>;
      price: number;
      maxConnections: number;
      maxRequests: number;
      logRetention?: SubscriptionPlanLogRetention | null;
      chartsTier?: SubscriptionPlanChartsTier | null;
      connectionsAllowed: Array<string>;
      methodsSupport: boolean;
      whitelistSupport: boolean;
      prioritarySupport: boolean;
      advancedQueries: boolean;
      apiKey: boolean;
      apiKeyRenewable: boolean;
      customizableCache: boolean;
      paypalPlanId?: string | null;
      stripeProductId?: string | null;
      mostPopular: boolean;
      hasTrial: boolean;
      nextTier?: { __typename?: 'SubscriptionPlanType'; id: string } | null;
    };
  } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    isActive: boolean;
    dateJoined: any;
    id: string;
    hitsCached: number;
    hitsCachedTotal: number;
    stripeSubscriptionId?: string | null;
    stripeCustomerId?: string | null;
    subscriptionFailureFlag: boolean;
    pk?: any | null;
    limit?: number | null;
    remaining?: number | null;
    hash?: string | null;
    lastLogin?: any | null;
    subscription: {
      __typename?: 'SubscriptionPlanType';
      id: string;
      code: string;
      name: string;
      description: Array<string>;
      price: number;
      maxConnections: number;
      maxRequests: number;
      logRetention?: SubscriptionPlanLogRetention | null;
      chartsTier?: SubscriptionPlanChartsTier | null;
      connectionsAllowed: Array<string>;
      methodsSupport: boolean;
      whitelistSupport: boolean;
      prioritarySupport: boolean;
      advancedQueries: boolean;
      apiKey: boolean;
      apiKeyRenewable: boolean;
      customizableCache: boolean;
      paypalPlanId?: string | null;
      stripeProductId?: string | null;
      mostPopular: boolean;
      hasTrial: boolean;
      nextTier?: { __typename?: 'SubscriptionPlanType'; id: string } | null;
    };
  } | null;
};

export type UserLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type UserLogoutMutation = {
  __typename?: 'Mutation';
  userLogout?: {
    __typename?: 'UserLogoutMutationPayload';
    success?: boolean | null;
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
  } | null;
};

export type UserDeactivateMutationVariables = Exact<{
  uid: Scalars['String'];
}>;

export type UserDeactivateMutation = {
  __typename?: 'Mutation';
  userDeactivate?: {
    __typename?: 'UserDeactivateMutationPayload';
    success?: boolean | null;
  } | null;
};

export type AccountUsageQueryVariables = Exact<{ [key: string]: never }>;

export type AccountUsageQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserType';
    hitsCached: number;
    limit?: number | null;
    subscription: {
      __typename?: 'SubscriptionPlanType';
      id: string;
      maxConnections: number;
      paypalPlanId?: string | null;
      stripeProductId?: string | null;
      nextTier?: { __typename?: 'SubscriptionPlanType'; id: string } | null;
    };
  } | null;
  connections?: { __typename?: 'ConnectionTypeConnection'; totalCount?: number | null } | null;
};

export type ErrorFieldsFragment = {
  __typename?: 'MutationErrorType';
  field?: string | null;
  message?: string | null;
};

export type PageInfoFieldsFragment = {
  __typename?: 'PageInfo';
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string | null;
  endCursor?: string | null;
};

export type ConnectionFieldsFragment = {
  __typename?: 'ConnectionType';
  id: string;
  url?: string | null;
  kind: ConnectionKind;
  name?: string | null;
  apiUrl: string;
  createdAt: any;
  updatedAt: any;
  hitsCached: number;
  hitsCachedTotal: number;
  lastHittedAt?: any | null;
  totalRows?: number | null;
  remaining: number;
  starred?: boolean | null;
  ttl: number;
  allowedMethods?: Array<string> | null;
  allowedIpsEnabled?: boolean | null;
  allowedIps?: Array<string | null> | null;
  allowedOriginsEnabled?: boolean | null;
  allowedOrigins?: Array<string> | null;
  apiKeyEnabled?: boolean | null;
  apiKey?: string | null;
};

export type ConnectionHitByPeriodFieldsFragment = {
  __typename?: 'ConnectionHitsByPeriodType';
  hits: number;
  period?: string | null;
  method?: string | null;
};

export type ConnectionHitFieldsFragment = {
  __typename?: 'ConnectionHitType';
  id: string;
  createdAt: any;
  ipAddress: string;
  method: ConnectionHitMethod;
  path: string;
  params: any;
  data: any;
  kind?: ConnectionKind | null;
  connection?: { __typename?: 'ConnectionType'; name?: string | null } | null;
};

export type ConnectionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  starred?: InputMaybe<Scalars['Boolean']>;
}>;

export type ConnectionsQuery = {
  __typename?: 'Query';
  connections?: {
    __typename?: 'ConnectionTypeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename?: 'ConnectionTypeEdge';
      node?: {
        __typename?: 'ConnectionType';
        id: string;
        url?: string | null;
        kind: ConnectionKind;
        name?: string | null;
        apiUrl: string;
        createdAt: any;
        updatedAt: any;
        hitsCached: number;
        hitsCachedTotal: number;
        lastHittedAt?: any | null;
        totalRows?: number | null;
        remaining: number;
        starred?: boolean | null;
        ttl: number;
        allowedMethods?: Array<string> | null;
        allowedIpsEnabled?: boolean | null;
        allowedIps?: Array<string | null> | null;
        allowedOriginsEnabled?: boolean | null;
        allowedOrigins?: Array<string> | null;
        apiKeyEnabled?: boolean | null;
        apiKey?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type ConnectionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ConnectionQuery = {
  __typename?: 'Query';
  connection?: {
    __typename?: 'ConnectionType';
    id: string;
    url?: string | null;
    kind: ConnectionKind;
    name?: string | null;
    apiUrl: string;
    createdAt: any;
    updatedAt: any;
    hitsCached: number;
    hitsCachedTotal: number;
    lastHittedAt?: any | null;
    totalRows?: number | null;
    remaining: number;
    starred?: boolean | null;
    ttl: number;
    allowedMethods?: Array<string> | null;
    allowedIpsEnabled?: boolean | null;
    allowedIps?: Array<string | null> | null;
    allowedOriginsEnabled?: boolean | null;
    allowedOrigins?: Array<string> | null;
    apiKeyEnabled?: boolean | null;
    apiKey?: string | null;
  } | null;
};

export type ConnectionValidateMutationVariables = Exact<{
  kind: ConnectionKind;
  url: Scalars['String'];
}>;

export type ConnectionValidateMutation = {
  __typename?: 'Mutation';
  connectionValidateUrl?: {
    __typename?: 'ConnectionValidateUrlMutationPayload';
    isValid?: boolean | null;
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
  } | null;
};

export type ConnectionCreateMutationVariables = Exact<{
  input: ConnectionCreateMutationInput;
}>;

export type ConnectionCreateMutation = {
  __typename?: 'Mutation';
  connectionCreate?: {
    __typename?: 'ConnectionCreateMutationPayload';
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
    connection?: {
      __typename?: 'ConnectionType';
      id: string;
      url?: string | null;
      kind: ConnectionKind;
      name?: string | null;
      apiUrl: string;
      createdAt: any;
      updatedAt: any;
      hitsCached: number;
      hitsCachedTotal: number;
      lastHittedAt?: any | null;
      totalRows?: number | null;
      remaining: number;
      starred?: boolean | null;
      ttl: number;
      allowedMethods?: Array<string> | null;
      allowedIpsEnabled?: boolean | null;
      allowedIps?: Array<string | null> | null;
      allowedOriginsEnabled?: boolean | null;
      allowedOrigins?: Array<string> | null;
      apiKeyEnabled?: boolean | null;
      apiKey?: string | null;
    } | null;
  } | null;
};

export type ConnectionUpdateMutationVariables = Exact<{
  input: ConnectionUpdateMutationInput;
}>;

export type ConnectionUpdateMutation = {
  __typename?: 'Mutation';
  connectionUpdate?: {
    __typename?: 'ConnectionUpdateMutationPayload';
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
    connection?: {
      __typename?: 'ConnectionType';
      id: string;
      url?: string | null;
      kind: ConnectionKind;
      name?: string | null;
      apiUrl: string;
      createdAt: any;
      updatedAt: any;
      hitsCached: number;
      hitsCachedTotal: number;
      lastHittedAt?: any | null;
      totalRows?: number | null;
      remaining: number;
      starred?: boolean | null;
      ttl: number;
      allowedMethods?: Array<string> | null;
      allowedIpsEnabled?: boolean | null;
      allowedIps?: Array<string | null> | null;
      allowedOriginsEnabled?: boolean | null;
      allowedOrigins?: Array<string> | null;
      apiKeyEnabled?: boolean | null;
      apiKey?: string | null;
    } | null;
  } | null;
};

export type ConnectionDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ConnectionDeleteMutation = {
  __typename?: 'Mutation';
  connectionDelete?: {
    __typename?: 'ConnectionDeleteMutationPayload';
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
    connection?: {
      __typename?: 'ConnectionType';
      id: string;
      url?: string | null;
      kind: ConnectionKind;
      name?: string | null;
      apiUrl: string;
      createdAt: any;
      updatedAt: any;
      hitsCached: number;
      hitsCachedTotal: number;
      lastHittedAt?: any | null;
      totalRows?: number | null;
      remaining: number;
      starred?: boolean | null;
      ttl: number;
      allowedMethods?: Array<string> | null;
      allowedIpsEnabled?: boolean | null;
      allowedIps?: Array<string | null> | null;
      allowedOriginsEnabled?: boolean | null;
      allowedOrigins?: Array<string> | null;
      apiKeyEnabled?: boolean | null;
      apiKey?: string | null;
    } | null;
  } | null;
};

export type ConnectionHitsByPeriodQueryVariables = Exact<{
  start: Scalars['Date'];
  end: Scalars['Date'];
  periodType: PeriodType;
  connection?: InputMaybe<Scalars['ID']>;
  groupByMethod?: InputMaybe<Scalars['Boolean']>;
  tz?: InputMaybe<Scalars['String']>;
}>;

export type ConnectionHitsByPeriodQuery = {
  __typename?: 'Query';
  connectionHitsByPeriod?: Array<{
    __typename?: 'ConnectionHitsByPeriodType';
    hits: number;
    period?: string | null;
    method?: string | null;
  }> | null;
};

export type ConnectionHitsQueryVariables = Exact<{
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  method?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  connection?: InputMaybe<Scalars['ID']>;
}>;

export type ConnectionHitsQuery = {
  __typename?: 'Query';
  connectionHits?: {
    __typename?: 'ConnectionHitTypeConnection';
    totalCount?: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      __typename?: 'ConnectionHitTypeEdge';
      node?: {
        __typename?: 'ConnectionHitType';
        id: string;
        createdAt: any;
        ipAddress: string;
        method: ConnectionHitMethod;
        path: string;
        params: any;
        data: any;
        kind?: ConnectionKind | null;
        connection?: { __typename?: 'ConnectionType'; name?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateFreeSheetMutationVariables = Exact<{
  url: Scalars['String'];
  user?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
}>;

export type CreateFreeSheetMutation = {
  __typename?: 'Mutation';
  sheetCreate?: {
    __typename?: 'SheetCreateMutationPayload';
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
    sheet?: {
      __typename?: 'SheetType';
      id: string;
      starred?: boolean | null;
      pk: any;
      name?: string | null;
      url: string;
      createdAt: any;
      lastHittedAt?: any | null;
      hitsCachedTotal: number;
      totalRows?: number | null;
      ttl: number;
      allowedMethods?: Array<string> | null;
      apiKeyEnabled?: boolean | null;
      apiKey?: string | null;
      allowedIpsEnabled?: boolean | null;
      allowedIps?: Array<string | null> | null;
      allowedOriginsEnabled?: boolean | null;
      allowedOrigins?: Array<string> | null;
    } | null;
  } | null;
};

export type DeleteSheetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteSheetMutation = {
  __typename?: 'Mutation';
  sheetDelete?: {
    __typename?: 'SheetDeleteMutationPayload';
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
    sheet?: { __typename?: 'SheetType'; id: string } | null;
  } | null;
};

export type GetSheetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSheetsQuery = {
  __typename?: 'Query';
  sheets?: {
    __typename?: 'SheetTypeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename?: 'SheetTypeEdge';
      node?: {
        __typename?: 'SheetType';
        id: string;
        starred?: boolean | null;
        pk: any;
        name?: string | null;
        url: string;
        createdAt: any;
        lastHittedAt?: any | null;
        hitsCachedTotal: number;
        totalRows?: number | null;
        ttl: number;
        allowedMethods?: Array<string> | null;
        apiKeyEnabled?: boolean | null;
        apiKey?: string | null;
        allowedIpsEnabled?: boolean | null;
        allowedIps?: Array<string | null> | null;
        allowedOriginsEnabled?: boolean | null;
        allowedOrigins?: Array<string> | null;
      } | null;
    } | null>;
  } | null;
};

export type LatestHitsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;

export type LatestHitsQuery = {
  __typename?: 'Query';
  sheetHits?: {
    __typename?: 'SheetHitTypeConnection';
    edges: Array<{
      __typename?: 'SheetHitTypeEdge';
      node?: {
        __typename?: 'SheetHitType';
        ipAddress: string;
        method: SheetHitMethod;
        path: string;
        id: string;
        data: any;
        params: any;
        createdAt: any;
        updatedAt: any;
        connection: { __typename?: 'SheetType'; name?: string | null; id: string };
      } | null;
    } | null>;
  } | null;
};

export type SheetHitsByPeriodQueryVariables = Exact<{
  start: Scalars['Date'];
  end: Scalars['Date'];
  periodType: PeriodType;
  groupByMethod?: InputMaybe<Scalars['Boolean']>;
  sheet?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
}>;

export type SheetHitsByPeriodQuery = {
  __typename?: 'Query';
  sheetHitsByPeriod?: Array<{
    __typename?: 'ConnectionHitsByPeriodType';
    hits: number;
    period?: string | null;
    method?: string | null;
  }> | null;
};

export type SheetPublicQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type SheetPublicQuery = {
  __typename?: 'Query';
  sheetPublic?: {
    __typename?: 'SheetPublicType';
    url?: string | null;
    hitsCached: number;
    limit: number;
    remaining: number;
  } | null;
};

export type StarToggleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StarToggleMutation = {
  __typename?: 'Mutation';
  sheetStarToggle?: {
    __typename?: 'SheetStarToggleMutationPayload';
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
    sheet?: { __typename?: 'SheetType'; id: string; starred?: boolean | null } | null;
  } | null;
};

export type SubscriptionPlansFieldsFragment = {
  __typename?: 'SubscriptionPlanType';
  id: string;
  code: string;
  name: string;
  price: number;
  description: Array<string>;
  maxConnections: number;
  maxRequests: number;
  logRetention?: SubscriptionPlanLogRetention | null;
  chartsTier?: SubscriptionPlanChartsTier | null;
  connectionsAllowed: Array<string>;
  methodsSupport: boolean;
  whitelistSupport: boolean;
  prioritarySupport: boolean;
  advancedQueries: boolean;
  apiKey: boolean;
  apiKeyRenewable: boolean;
  customizableCache: boolean;
  paypalPlanId?: string | null;
  stripeProductId?: string | null;
  mostPopular: boolean;
  hasTrial: boolean;
};

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionPlansQuery = {
  __typename?: 'Query';
  subscriptionPlans?: Array<{
    __typename?: 'SubscriptionPlanType';
    id: string;
    code: string;
    name: string;
    price: number;
    description: Array<string>;
    maxConnections: number;
    maxRequests: number;
    logRetention?: SubscriptionPlanLogRetention | null;
    chartsTier?: SubscriptionPlanChartsTier | null;
    connectionsAllowed: Array<string>;
    methodsSupport: boolean;
    whitelistSupport: boolean;
    prioritarySupport: boolean;
    advancedQueries: boolean;
    apiKey: boolean;
    apiKeyRenewable: boolean;
    customizableCache: boolean;
    paypalPlanId?: string | null;
    stripeProductId?: string | null;
    mostPopular: boolean;
    hasTrial: boolean;
  }> | null;
};

export type UserSubscriptionCreateMutationVariables = Exact<{
  paypalSubscriptionId: Scalars['String'];
  userId: Scalars['ID'];
}>;

export type UserSubscriptionCreateMutation = {
  __typename?: 'Mutation';
  userSubscriptionCreate?: {
    __typename?: 'UserSubscriptionCreateMutationPayload';
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
    user?: {
      __typename?: 'UserType';
      id: string;
      subscription: {
        __typename?: 'SubscriptionPlanType';
        id: string;
        code: string;
        name: string;
        description: Array<string>;
        price: number;
        maxConnections: number;
        maxRequests: number;
        logRetention?: SubscriptionPlanLogRetention | null;
        chartsTier?: SubscriptionPlanChartsTier | null;
        connectionsAllowed: Array<string>;
        methodsSupport: boolean;
        whitelistSupport: boolean;
        prioritarySupport: boolean;
        advancedQueries: boolean;
        apiKey: boolean;
        apiKeyRenewable: boolean;
        customizableCache: boolean;
        paypalPlanId?: string | null;
        stripeProductId?: string | null;
        mostPopular: boolean;
        hasTrial: boolean;
        nextTier?: { __typename?: 'SubscriptionPlanType'; id: string } | null;
      };
    } | null;
  } | null;
};

export type UserSubscriptionChangeMutationVariables = Exact<{
  subscriptionPlan: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type UserSubscriptionChangeMutation = {
  __typename?: 'Mutation';
  userSubscriptionChange?: {
    __typename?: 'UserSubscriptionChangeMutationPayload';
    needApproval?: boolean | null;
    approvalLink?: string | null;
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
    user?: {
      __typename?: 'UserType';
      id: string;
      subscription: {
        __typename?: 'SubscriptionPlanType';
        id: string;
        code: string;
        name: string;
        description: Array<string>;
        price: number;
        maxConnections: number;
        maxRequests: number;
        logRetention?: SubscriptionPlanLogRetention | null;
        chartsTier?: SubscriptionPlanChartsTier | null;
        connectionsAllowed: Array<string>;
        methodsSupport: boolean;
        whitelistSupport: boolean;
        prioritarySupport: boolean;
        advancedQueries: boolean;
        apiKey: boolean;
        apiKeyRenewable: boolean;
        customizableCache: boolean;
        paypalPlanId?: string | null;
        stripeProductId?: string | null;
        mostPopular: boolean;
        hasTrial: boolean;
        nextTier?: { __typename?: 'SubscriptionPlanType'; id: string } | null;
      };
    } | null;
  } | null;
};

export type UserSubscriptionChangeConfirmMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserSubscriptionChangeConfirmMutation = {
  __typename?: 'Mutation';
  userSubscriptionChangeConfirm?: {
    __typename?: 'UserSubscriptionChangeConfirmMutationPayload';
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
    user?: {
      __typename?: 'UserType';
      id: string;
      subscription: {
        __typename?: 'SubscriptionPlanType';
        id: string;
        code: string;
        name: string;
        description: Array<string>;
        price: number;
        maxConnections: number;
        maxRequests: number;
        logRetention?: SubscriptionPlanLogRetention | null;
        chartsTier?: SubscriptionPlanChartsTier | null;
        connectionsAllowed: Array<string>;
        methodsSupport: boolean;
        whitelistSupport: boolean;
        prioritarySupport: boolean;
        advancedQueries: boolean;
        apiKey: boolean;
        apiKeyRenewable: boolean;
        customizableCache: boolean;
        paypalPlanId?: string | null;
        stripeProductId?: string | null;
        mostPopular: boolean;
        hasTrial: boolean;
        nextTier?: { __typename?: 'SubscriptionPlanType'; id: string } | null;
      };
    } | null;
  } | null;
};

export type SubscriptionPlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscriptionPlanQuery = {
  __typename?: 'Query';
  subscriptionPlan?: {
    __typename?: 'SubscriptionPlanType';
    id: string;
    code: string;
    name: string;
    description: Array<string>;
    price: number;
    maxConnections: number;
    maxRequests: number;
    logRetention?: SubscriptionPlanLogRetention | null;
    chartsTier?: SubscriptionPlanChartsTier | null;
    connectionsAllowed: Array<string>;
    methodsSupport: boolean;
    whitelistSupport: boolean;
    prioritarySupport: boolean;
    advancedQueries: boolean;
    apiKey: boolean;
    apiKeyRenewable: boolean;
    customizableCache: boolean;
    paypalPlanId?: string | null;
    stripeProductId?: string | null;
    mostPopular: boolean;
    hasTrial: boolean;
    nextTier?: { __typename?: 'SubscriptionPlanType'; id: string } | null;
  } | null;
};

export type UserStripeCheckoutSessionCreateMutationVariables = Exact<{
  planId: Scalars['String'];
  userId: Scalars['ID'];
}>;

export type UserStripeCheckoutSessionCreateMutation = {
  __typename?: 'Mutation';
  userStripeCheckoutSessionCreate?: {
    __typename?: 'UserStripeCheckoutSessionCreateMutationPayload';
    sessionId?: string | null;
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
  } | null;
};

export type UpdateSheetMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  starred: Scalars['Boolean'];
  apiKeyEnabled: Scalars['Boolean'];
  apiKey?: InputMaybe<Scalars['String']>;
  allowedMethods?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  allowedOriginsEnabled: Scalars['Boolean'];
  allowedOrigins?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  allowedIpsEnabled: Scalars['Boolean'];
  allowedIps?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  ttl?: InputMaybe<Scalars['Int']>;
}>;

export type UpdateSheetMutation = {
  __typename?: 'Mutation';
  sheetUpdate?: {
    __typename?: 'SheetUpdateMutationPayload';
    errors?: Array<{
      __typename?: 'MutationErrorType';
      field?: string | null;
      message?: string | null;
    }> | null;
    sheet?: {
      __typename?: 'SheetType';
      id: string;
      name?: string | null;
      starred?: boolean | null;
      apiKeyEnabled?: boolean | null;
      apiKey?: string | null;
      allowedMethods?: Array<string> | null;
      allowedOriginsEnabled?: boolean | null;
      allowedOrigins?: Array<string> | null;
      allowedIpsEnabled?: boolean | null;
      allowedIps?: Array<string | null> | null;
      ttl: number;
    } | null;
  } | null;
};

export type ValidateUrlMutationVariables = Exact<{
  url: Scalars['String'];
}>;

export type ValidateUrlMutation = {
  __typename?: 'Mutation';
  sheetValidateUrl?: {
    __typename?: 'SheetValidateUrlMutationPayload';
    isValid?: boolean | null;
    errors?: Array<{ __typename?: 'MutationErrorType'; message?: string | null }> | null;
  } | null;
};

export const SubscriptionFieldsFragmentDoc = gql`
  fragment subscriptionFields on SubscriptionPlanType {
    id
    code
    name
    description
    price
    maxConnections
    maxRequests
    logRetention
    chartsTier
    connectionsAllowed
    methodsSupport
    whitelistSupport
    prioritarySupport
    advancedQueries
    apiKey
    apiKeyRenewable
    customizableCache
    paypalPlanId
    stripeProductId
    mostPopular
    hasTrial
    nextTier {
      id
    }
  }
`;
export const MeFieldsFragmentDoc = gql`
  fragment meFields on UserType {
    username
    firstName
    lastName
    email
    isActive
    dateJoined
    id
    hitsCached
    hitsCachedTotal
    stripeSubscriptionId
    stripeCustomerId
    subscriptionFailureFlag
    pk
    limit
    remaining
    hash
    lastLogin
    subscription {
      ...subscriptionFields
    }
  }
  ${SubscriptionFieldsFragmentDoc}
`;
export const ErrorFieldsFragmentDoc = gql`
  fragment errorFields on MutationErrorType {
    field
    message
  }
`;
export const PageInfoFieldsFragmentDoc = gql`
  fragment pageInfoFields on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
export const ConnectionFieldsFragmentDoc = gql`
  fragment connectionFields on ConnectionType {
    id
    url
    kind
    name
    apiUrl
    createdAt
    updatedAt
    hitsCached
    hitsCachedTotal
    lastHittedAt
    totalRows
    remaining
    starred
    ttl
    allowedMethods
    allowedIpsEnabled
    allowedIps
    allowedOriginsEnabled
    allowedOrigins
    apiKeyEnabled
    apiKey
  }
`;
export const ConnectionHitByPeriodFieldsFragmentDoc = gql`
  fragment connectionHitByPeriodFields on ConnectionHitsByPeriodType {
    hits
    period
    method
  }
`;
export const ConnectionHitFieldsFragmentDoc = gql`
  fragment connectionHitFields on ConnectionHitType {
    id
    createdAt
    ipAddress
    method
    path
    params
    data
    kind
    connection {
      name
    }
  }
`;
export const SubscriptionPlansFieldsFragmentDoc = gql`
  fragment subscriptionPlansFields on SubscriptionPlanType {
    id
    code
    name
    price
    description
    maxConnections
    maxRequests
    logRetention
    chartsTier
    connectionsAllowed
    methodsSupport
    whitelistSupport
    prioritarySupport
    advancedQueries
    apiKey
    apiKeyRenewable
    customizableCache
    paypalPlanId
    stripeProductId
    mostPopular
    hasTrial
  }
`;
export const AuthDocument = gql`
  query Auth {
    me {
      id
      token
      scopes
      stripeSubscriptionId
      stripeCustomerId
      subscription {
        ...subscriptionFields
      }
    }
  }
  ${SubscriptionFieldsFragmentDoc}
`;
export function useAuthQuery(baseOptions?: Apollo.QueryHookOptions<AuthQuery, AuthQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthQuery, AuthQueryVariables>(AuthDocument, options);
}
export function useAuthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuthQuery, AuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthQuery, AuthQueryVariables>(AuthDocument, options);
}
export type AuthQueryHookResult = ReturnType<typeof useAuthQuery>;
export type AuthLazyQueryHookResult = ReturnType<typeof useAuthLazyQuery>;
export type AuthQueryResult = Apollo.QueryResult<AuthQuery, AuthQueryVariables>;
export const MeDocument = gql`
  query Me {
    me {
      ...meFields
    }
  }
  ${MeFieldsFragmentDoc}
`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserLogoutDocument = gql`
  mutation userLogout {
    userLogout(input: {}) {
      errors {
        ...errorFields
      }
      success
    }
  }
  ${ErrorFieldsFragmentDoc}
`;
export type UserLogoutMutationFn = Apollo.MutationFunction<
  UserLogoutMutation,
  UserLogoutMutationVariables
>;
export function useUserLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<UserLogoutMutation, UserLogoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserLogoutMutation, UserLogoutMutationVariables>(
    UserLogoutDocument,
    options
  );
}
export type UserLogoutMutationHookResult = ReturnType<typeof useUserLogoutMutation>;
export type UserLogoutMutationResult = Apollo.MutationResult<UserLogoutMutation>;
export type UserLogoutMutationOptions = Apollo.BaseMutationOptions<
  UserLogoutMutation,
  UserLogoutMutationVariables
>;
export const UserDeactivateDocument = gql`
  mutation userDeactivate($uid: String!) {
    userDeactivate(input: { uid: $uid }) {
      success
    }
  }
`;
export type UserDeactivateMutationFn = Apollo.MutationFunction<
  UserDeactivateMutation,
  UserDeactivateMutationVariables
>;
export function useUserDeactivateMutation(
  baseOptions?: Apollo.MutationHookOptions<UserDeactivateMutation, UserDeactivateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserDeactivateMutation, UserDeactivateMutationVariables>(
    UserDeactivateDocument,
    options
  );
}
export type UserDeactivateMutationHookResult = ReturnType<typeof useUserDeactivateMutation>;
export type UserDeactivateMutationResult = Apollo.MutationResult<UserDeactivateMutation>;
export type UserDeactivateMutationOptions = Apollo.BaseMutationOptions<
  UserDeactivateMutation,
  UserDeactivateMutationVariables
>;
export const AccountUsageDocument = gql`
  query AccountUsage {
    me {
      hitsCached
      limit
      subscription {
        id
        maxConnections
        paypalPlanId
        stripeProductId
        nextTier {
          id
        }
      }
    }
    connections {
      totalCount
    }
  }
`;
export function useAccountUsageQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountUsageQuery, AccountUsageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountUsageQuery, AccountUsageQueryVariables>(
    AccountUsageDocument,
    options
  );
}
export function useAccountUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountUsageQuery, AccountUsageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountUsageQuery, AccountUsageQueryVariables>(
    AccountUsageDocument,
    options
  );
}
export type AccountUsageQueryHookResult = ReturnType<typeof useAccountUsageQuery>;
export type AccountUsageLazyQueryHookResult = ReturnType<typeof useAccountUsageLazyQuery>;
export type AccountUsageQueryResult = Apollo.QueryResult<
  AccountUsageQuery,
  AccountUsageQueryVariables
>;
export const ConnectionsDocument = gql`
  query Connections(
    $first: Int
    $orderby: [String]
    $after: String
    $kind: String
    $search: String
    $starred: Boolean
  ) {
    connections(
      first: $first
      orderby: $orderby
      after: $after
      kind: $kind
      search: $search
      starred: $starred
    ) {
      totalCount
      edges {
        node {
          ...connectionFields
        }
      }
    }
  }
  ${ConnectionFieldsFragmentDoc}
`;
export function useConnectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, options);
}
export function useConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConnectionsQuery, ConnectionsQueryVariables>(
    ConnectionsDocument,
    options
  );
}
export type ConnectionsQueryHookResult = ReturnType<typeof useConnectionsQuery>;
export type ConnectionsLazyQueryHookResult = ReturnType<typeof useConnectionsLazyQuery>;
export type ConnectionsQueryResult = Apollo.QueryResult<
  ConnectionsQuery,
  ConnectionsQueryVariables
>;
export const ConnectionDocument = gql`
  query Connection($id: ID!) {
    connection(id: $id) {
      ...connectionFields
    }
  }
  ${ConnectionFieldsFragmentDoc}
`;
export function useConnectionQuery(
  baseOptions: Apollo.QueryHookOptions<ConnectionQuery, ConnectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConnectionQuery, ConnectionQueryVariables>(ConnectionDocument, options);
}
export function useConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConnectionQuery, ConnectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConnectionQuery, ConnectionQueryVariables>(
    ConnectionDocument,
    options
  );
}
export type ConnectionQueryHookResult = ReturnType<typeof useConnectionQuery>;
export type ConnectionLazyQueryHookResult = ReturnType<typeof useConnectionLazyQuery>;
export type ConnectionQueryResult = Apollo.QueryResult<ConnectionQuery, ConnectionQueryVariables>;
export const ConnectionValidateDocument = gql`
  mutation ConnectionValidate($kind: ConnectionKind!, $url: String!) {
    connectionValidateUrl(input: { kind: $kind, url: $url }) {
      errors {
        ...errorFields
      }
      isValid
    }
  }
  ${ErrorFieldsFragmentDoc}
`;
export type ConnectionValidateMutationFn = Apollo.MutationFunction<
  ConnectionValidateMutation,
  ConnectionValidateMutationVariables
>;
export function useConnectionValidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectionValidateMutation,
    ConnectionValidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConnectionValidateMutation, ConnectionValidateMutationVariables>(
    ConnectionValidateDocument,
    options
  );
}
export type ConnectionValidateMutationHookResult = ReturnType<typeof useConnectionValidateMutation>;
export type ConnectionValidateMutationResult = Apollo.MutationResult<ConnectionValidateMutation>;
export type ConnectionValidateMutationOptions = Apollo.BaseMutationOptions<
  ConnectionValidateMutation,
  ConnectionValidateMutationVariables
>;
export const ConnectionCreateDocument = gql`
  mutation ConnectionCreate($input: ConnectionCreateMutationInput!) {
    connectionCreate(input: $input) {
      errors {
        ...errorFields
      }
      connection {
        ...connectionFields
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${ConnectionFieldsFragmentDoc}
`;
export type ConnectionCreateMutationFn = Apollo.MutationFunction<
  ConnectionCreateMutation,
  ConnectionCreateMutationVariables
>;
export function useConnectionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectionCreateMutation,
    ConnectionCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConnectionCreateMutation, ConnectionCreateMutationVariables>(
    ConnectionCreateDocument,
    options
  );
}
export type ConnectionCreateMutationHookResult = ReturnType<typeof useConnectionCreateMutation>;
export type ConnectionCreateMutationResult = Apollo.MutationResult<ConnectionCreateMutation>;
export type ConnectionCreateMutationOptions = Apollo.BaseMutationOptions<
  ConnectionCreateMutation,
  ConnectionCreateMutationVariables
>;
export const ConnectionUpdateDocument = gql`
  mutation ConnectionUpdate($input: ConnectionUpdateMutationInput!) {
    connectionUpdate(input: $input) {
      errors {
        ...errorFields
      }
      connection {
        ...connectionFields
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${ConnectionFieldsFragmentDoc}
`;
export type ConnectionUpdateMutationFn = Apollo.MutationFunction<
  ConnectionUpdateMutation,
  ConnectionUpdateMutationVariables
>;
export function useConnectionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectionUpdateMutation,
    ConnectionUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConnectionUpdateMutation, ConnectionUpdateMutationVariables>(
    ConnectionUpdateDocument,
    options
  );
}
export type ConnectionUpdateMutationHookResult = ReturnType<typeof useConnectionUpdateMutation>;
export type ConnectionUpdateMutationResult = Apollo.MutationResult<ConnectionUpdateMutation>;
export type ConnectionUpdateMutationOptions = Apollo.BaseMutationOptions<
  ConnectionUpdateMutation,
  ConnectionUpdateMutationVariables
>;
export const ConnectionDeleteDocument = gql`
  mutation ConnectionDelete($id: ID!) {
    connectionDelete(input: { id: $id }) {
      errors {
        ...errorFields
      }
      connection {
        ...connectionFields
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${ConnectionFieldsFragmentDoc}
`;
export type ConnectionDeleteMutationFn = Apollo.MutationFunction<
  ConnectionDeleteMutation,
  ConnectionDeleteMutationVariables
>;
export function useConnectionDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectionDeleteMutation,
    ConnectionDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConnectionDeleteMutation, ConnectionDeleteMutationVariables>(
    ConnectionDeleteDocument,
    options
  );
}
export type ConnectionDeleteMutationHookResult = ReturnType<typeof useConnectionDeleteMutation>;
export type ConnectionDeleteMutationResult = Apollo.MutationResult<ConnectionDeleteMutation>;
export type ConnectionDeleteMutationOptions = Apollo.BaseMutationOptions<
  ConnectionDeleteMutation,
  ConnectionDeleteMutationVariables
>;
export const ConnectionHitsByPeriodDocument = gql`
  query ConnectionHitsByPeriod(
    $start: Date!
    $end: Date!
    $periodType: PeriodType!
    $connection: ID
    $groupByMethod: Boolean
    $tz: String
  ) {
    connectionHitsByPeriod(
      start: $start
      end: $end
      periodType: $periodType
      connection: $connection
      groupByMethod: $groupByMethod
      tz: $tz
    ) {
      ...connectionHitByPeriodFields
    }
  }
  ${ConnectionHitByPeriodFieldsFragmentDoc}
`;
export function useConnectionHitsByPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectionHitsByPeriodQuery,
    ConnectionHitsByPeriodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConnectionHitsByPeriodQuery, ConnectionHitsByPeriodQueryVariables>(
    ConnectionHitsByPeriodDocument,
    options
  );
}
export function useConnectionHitsByPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectionHitsByPeriodQuery,
    ConnectionHitsByPeriodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConnectionHitsByPeriodQuery, ConnectionHitsByPeriodQueryVariables>(
    ConnectionHitsByPeriodDocument,
    options
  );
}
export type ConnectionHitsByPeriodQueryHookResult = ReturnType<
  typeof useConnectionHitsByPeriodQuery
>;
export type ConnectionHitsByPeriodLazyQueryHookResult = ReturnType<
  typeof useConnectionHitsByPeriodLazyQuery
>;
export type ConnectionHitsByPeriodQueryResult = Apollo.QueryResult<
  ConnectionHitsByPeriodQuery,
  ConnectionHitsByPeriodQueryVariables
>;
export const ConnectionHitsDocument = gql`
  query ConnectionHits(
    $orderby: [String]
    $after: String
    $first: Int = 100
    $start: DateTime
    $end: DateTime
    $method: String
    $source: String
    $connection: ID
  ) {
    connectionHits(
      orderby: $orderby
      after: $after
      first: $first
      createdAt_Gte: $start
      createdAt_Lte: $end
      method_Iexact: $method
      source: $source
      connection: $connection
    ) {
      totalCount
      pageInfo {
        ...pageInfoFields
      }
      edges {
        node {
          ...connectionHitFields
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${ConnectionHitFieldsFragmentDoc}
`;
export function useConnectionHitsQuery(
  baseOptions?: Apollo.QueryHookOptions<ConnectionHitsQuery, ConnectionHitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConnectionHitsQuery, ConnectionHitsQueryVariables>(
    ConnectionHitsDocument,
    options
  );
}
export function useConnectionHitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConnectionHitsQuery, ConnectionHitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConnectionHitsQuery, ConnectionHitsQueryVariables>(
    ConnectionHitsDocument,
    options
  );
}
export type ConnectionHitsQueryHookResult = ReturnType<typeof useConnectionHitsQuery>;
export type ConnectionHitsLazyQueryHookResult = ReturnType<typeof useConnectionHitsLazyQuery>;
export type ConnectionHitsQueryResult = Apollo.QueryResult<
  ConnectionHitsQuery,
  ConnectionHitsQueryVariables
>;
export const CreateFreeSheetDocument = gql`
  mutation createFreeSheet($url: String!, $user: ID, $name: String) {
    sheetCreate(input: { url: $url, user: $user, name: $name }) {
      errors {
        field
        message
      }
      sheet {
        id
        starred
        pk
        name
        url
        createdAt
        lastHittedAt
        hitsCachedTotal
        totalRows
        ttl
        allowedMethods
        apiKeyEnabled
        apiKey
        allowedIpsEnabled
        allowedIps
        allowedOriginsEnabled
        allowedOrigins
      }
    }
  }
`;
export type CreateFreeSheetMutationFn = Apollo.MutationFunction<
  CreateFreeSheetMutation,
  CreateFreeSheetMutationVariables
>;
export function useCreateFreeSheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFreeSheetMutation,
    CreateFreeSheetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFreeSheetMutation, CreateFreeSheetMutationVariables>(
    CreateFreeSheetDocument,
    options
  );
}
export type CreateFreeSheetMutationHookResult = ReturnType<typeof useCreateFreeSheetMutation>;
export type CreateFreeSheetMutationResult = Apollo.MutationResult<CreateFreeSheetMutation>;
export type CreateFreeSheetMutationOptions = Apollo.BaseMutationOptions<
  CreateFreeSheetMutation,
  CreateFreeSheetMutationVariables
>;
export const DeleteSheetDocument = gql`
  mutation deleteSheet($id: ID!) {
    sheetDelete(input: { id: $id }) {
      errors {
        field
        message
      }
      sheet {
        id
      }
    }
  }
`;
export type DeleteSheetMutationFn = Apollo.MutationFunction<
  DeleteSheetMutation,
  DeleteSheetMutationVariables
>;
export function useDeleteSheetMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSheetMutation, DeleteSheetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSheetMutation, DeleteSheetMutationVariables>(
    DeleteSheetDocument,
    options
  );
}
export type DeleteSheetMutationHookResult = ReturnType<typeof useDeleteSheetMutation>;
export type DeleteSheetMutationResult = Apollo.MutationResult<DeleteSheetMutation>;
export type DeleteSheetMutationOptions = Apollo.BaseMutationOptions<
  DeleteSheetMutation,
  DeleteSheetMutationVariables
>;
export const GetSheetsDocument = gql`
  query getSheets {
    sheets {
      edges {
        node {
          id
          starred
          pk
          name
          url
          createdAt
          lastHittedAt
          hitsCachedTotal
          totalRows
          ttl
          allowedMethods
          apiKeyEnabled
          apiKey
          allowedIpsEnabled
          allowedIps
          allowedOriginsEnabled
          allowedOrigins
        }
      }
      totalCount
    }
  }
`;
export function useGetSheetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSheetsQuery, GetSheetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSheetsQuery, GetSheetsQueryVariables>(GetSheetsDocument, options);
}
export function useGetSheetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSheetsQuery, GetSheetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSheetsQuery, GetSheetsQueryVariables>(GetSheetsDocument, options);
}
export type GetSheetsQueryHookResult = ReturnType<typeof useGetSheetsQuery>;
export type GetSheetsLazyQueryHookResult = ReturnType<typeof useGetSheetsLazyQuery>;
export type GetSheetsQueryResult = Apollo.QueryResult<GetSheetsQuery, GetSheetsQueryVariables>;
export const LatestHitsDocument = gql`
  query LatestHits($limit: Int!) {
    sheetHits(first: $limit) {
      edges {
        node {
          ipAddress
          method
          path
          id
          data
          params
          createdAt
          updatedAt
          connection {
            name
            id
          }
        }
      }
    }
  }
`;
export function useLatestHitsQuery(
  baseOptions: Apollo.QueryHookOptions<LatestHitsQuery, LatestHitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestHitsQuery, LatestHitsQueryVariables>(LatestHitsDocument, options);
}
export function useLatestHitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LatestHitsQuery, LatestHitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LatestHitsQuery, LatestHitsQueryVariables>(
    LatestHitsDocument,
    options
  );
}
export type LatestHitsQueryHookResult = ReturnType<typeof useLatestHitsQuery>;
export type LatestHitsLazyQueryHookResult = ReturnType<typeof useLatestHitsLazyQuery>;
export type LatestHitsQueryResult = Apollo.QueryResult<LatestHitsQuery, LatestHitsQueryVariables>;
export const SheetHitsByPeriodDocument = gql`
  query sheetHitsByPeriod(
    $start: Date!
    $end: Date!
    $periodType: PeriodType!
    $groupByMethod: Boolean = false
    $sheet: ID
    $timezone: String
  ) {
    sheetHitsByPeriod(
      start: $start
      end: $end
      periodType: $periodType
      groupByMethod: $groupByMethod
      sheet: $sheet
      tz: $timezone
    ) {
      hits
      period
      method
    }
  }
`;
export function useSheetHitsByPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<SheetHitsByPeriodQuery, SheetHitsByPeriodQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SheetHitsByPeriodQuery, SheetHitsByPeriodQueryVariables>(
    SheetHitsByPeriodDocument,
    options
  );
}
export function useSheetHitsByPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SheetHitsByPeriodQuery, SheetHitsByPeriodQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SheetHitsByPeriodQuery, SheetHitsByPeriodQueryVariables>(
    SheetHitsByPeriodDocument,
    options
  );
}
export type SheetHitsByPeriodQueryHookResult = ReturnType<typeof useSheetHitsByPeriodQuery>;
export type SheetHitsByPeriodLazyQueryHookResult = ReturnType<typeof useSheetHitsByPeriodLazyQuery>;
export type SheetHitsByPeriodQueryResult = Apollo.QueryResult<
  SheetHitsByPeriodQuery,
  SheetHitsByPeriodQueryVariables
>;
export const SheetPublicDocument = gql`
  query SheetPublic($id: UUID!) {
    sheetPublic(id: $id) {
      url
      hitsCached
      limit
      remaining
    }
  }
`;
export function useSheetPublicQuery(
  baseOptions: Apollo.QueryHookOptions<SheetPublicQuery, SheetPublicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SheetPublicQuery, SheetPublicQueryVariables>(SheetPublicDocument, options);
}
export function useSheetPublicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SheetPublicQuery, SheetPublicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SheetPublicQuery, SheetPublicQueryVariables>(
    SheetPublicDocument,
    options
  );
}
export type SheetPublicQueryHookResult = ReturnType<typeof useSheetPublicQuery>;
export type SheetPublicLazyQueryHookResult = ReturnType<typeof useSheetPublicLazyQuery>;
export type SheetPublicQueryResult = Apollo.QueryResult<
  SheetPublicQuery,
  SheetPublicQueryVariables
>;
export const StarToggleDocument = gql`
  mutation starToggle($id: ID!) {
    sheetStarToggle(input: { id: $id }) {
      errors {
        field
        message
      }
      sheet {
        id
        starred
      }
    }
  }
`;
export type StarToggleMutationFn = Apollo.MutationFunction<
  StarToggleMutation,
  StarToggleMutationVariables
>;
export function useStarToggleMutation(
  baseOptions?: Apollo.MutationHookOptions<StarToggleMutation, StarToggleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StarToggleMutation, StarToggleMutationVariables>(
    StarToggleDocument,
    options
  );
}
export type StarToggleMutationHookResult = ReturnType<typeof useStarToggleMutation>;
export type StarToggleMutationResult = Apollo.MutationResult<StarToggleMutation>;
export type StarToggleMutationOptions = Apollo.BaseMutationOptions<
  StarToggleMutation,
  StarToggleMutationVariables
>;
export const SubscriptionPlansDocument = gql`
  query SubscriptionPlans {
    subscriptionPlans {
      ...subscriptionPlansFields
    }
  }
  ${SubscriptionPlansFieldsFragmentDoc}
`;
export function useSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(
    SubscriptionPlansDocument,
    options
  );
}
export function useSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(
    SubscriptionPlansDocument,
    options
  );
}
export type SubscriptionPlansQueryHookResult = ReturnType<typeof useSubscriptionPlansQuery>;
export type SubscriptionPlansLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansLazyQuery>;
export type SubscriptionPlansQueryResult = Apollo.QueryResult<
  SubscriptionPlansQuery,
  SubscriptionPlansQueryVariables
>;
export const UserSubscriptionCreateDocument = gql`
  mutation UserSubscriptionCreate($paypalSubscriptionId: String!, $userId: ID!) {
    userSubscriptionCreate(input: { paypalSubscriptionId: $paypalSubscriptionId, id: $userId }) {
      errors {
        ...errorFields
      }
      user {
        id
        subscription {
          ...subscriptionFields
        }
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${SubscriptionFieldsFragmentDoc}
`;
export type UserSubscriptionCreateMutationFn = Apollo.MutationFunction<
  UserSubscriptionCreateMutation,
  UserSubscriptionCreateMutationVariables
>;
export function useUserSubscriptionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserSubscriptionCreateMutation,
    UserSubscriptionCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserSubscriptionCreateMutation,
    UserSubscriptionCreateMutationVariables
  >(UserSubscriptionCreateDocument, options);
}
export type UserSubscriptionCreateMutationHookResult = ReturnType<
  typeof useUserSubscriptionCreateMutation
>;
export type UserSubscriptionCreateMutationResult = Apollo.MutationResult<
  UserSubscriptionCreateMutation
>;
export type UserSubscriptionCreateMutationOptions = Apollo.BaseMutationOptions<
  UserSubscriptionCreateMutation,
  UserSubscriptionCreateMutationVariables
>;
export const UserSubscriptionChangeDocument = gql`
  mutation UserSubscriptionChange($subscriptionPlan: ID!, $userId: ID!) {
    userSubscriptionChange(input: { subscriptionPlan: $subscriptionPlan, id: $userId }) {
      errors {
        ...errorFields
      }
      needApproval
      approvalLink
      user {
        id
        subscription {
          ...subscriptionFields
        }
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${SubscriptionFieldsFragmentDoc}
`;
export type UserSubscriptionChangeMutationFn = Apollo.MutationFunction<
  UserSubscriptionChangeMutation,
  UserSubscriptionChangeMutationVariables
>;
export function useUserSubscriptionChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserSubscriptionChangeMutation,
    UserSubscriptionChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserSubscriptionChangeMutation,
    UserSubscriptionChangeMutationVariables
  >(UserSubscriptionChangeDocument, options);
}
export type UserSubscriptionChangeMutationHookResult = ReturnType<
  typeof useUserSubscriptionChangeMutation
>;
export type UserSubscriptionChangeMutationResult = Apollo.MutationResult<
  UserSubscriptionChangeMutation
>;
export type UserSubscriptionChangeMutationOptions = Apollo.BaseMutationOptions<
  UserSubscriptionChangeMutation,
  UserSubscriptionChangeMutationVariables
>;
export const UserSubscriptionChangeConfirmDocument = gql`
  mutation UserSubscriptionChangeConfirm($userId: ID!) {
    userSubscriptionChangeConfirm(input: { id: $userId }) {
      errors {
        ...errorFields
      }
      user {
        id
        subscription {
          ...subscriptionFields
        }
      }
    }
  }
  ${ErrorFieldsFragmentDoc}
  ${SubscriptionFieldsFragmentDoc}
`;
export type UserSubscriptionChangeConfirmMutationFn = Apollo.MutationFunction<
  UserSubscriptionChangeConfirmMutation,
  UserSubscriptionChangeConfirmMutationVariables
>;
export function useUserSubscriptionChangeConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserSubscriptionChangeConfirmMutation,
    UserSubscriptionChangeConfirmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserSubscriptionChangeConfirmMutation,
    UserSubscriptionChangeConfirmMutationVariables
  >(UserSubscriptionChangeConfirmDocument, options);
}
export type UserSubscriptionChangeConfirmMutationHookResult = ReturnType<
  typeof useUserSubscriptionChangeConfirmMutation
>;
export type UserSubscriptionChangeConfirmMutationResult = Apollo.MutationResult<
  UserSubscriptionChangeConfirmMutation
>;
export type UserSubscriptionChangeConfirmMutationOptions = Apollo.BaseMutationOptions<
  UserSubscriptionChangeConfirmMutation,
  UserSubscriptionChangeConfirmMutationVariables
>;
export const SubscriptionPlanDocument = gql`
  query SubscriptionPlan($id: ID!) {
    subscriptionPlan(id: $id) {
      ...subscriptionFields
    }
  }
  ${SubscriptionFieldsFragmentDoc}
`;
export function useSubscriptionPlanQuery(
  baseOptions: Apollo.QueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(
    SubscriptionPlanDocument,
    options
  );
}
export function useSubscriptionPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(
    SubscriptionPlanDocument,
    options
  );
}
export type SubscriptionPlanQueryHookResult = ReturnType<typeof useSubscriptionPlanQuery>;
export type SubscriptionPlanLazyQueryHookResult = ReturnType<typeof useSubscriptionPlanLazyQuery>;
export type SubscriptionPlanQueryResult = Apollo.QueryResult<
  SubscriptionPlanQuery,
  SubscriptionPlanQueryVariables
>;
export const UserStripeCheckoutSessionCreateDocument = gql`
  mutation UserStripeCheckoutSessionCreate($planId: String!, $userId: ID!) {
    userStripeCheckoutSessionCreate(input: { planId: $planId, id: $userId }) {
      errors {
        ...errorFields
      }
      sessionId
    }
  }
  ${ErrorFieldsFragmentDoc}
`;
export type UserStripeCheckoutSessionCreateMutationFn = Apollo.MutationFunction<
  UserStripeCheckoutSessionCreateMutation,
  UserStripeCheckoutSessionCreateMutationVariables
>;
export function useUserStripeCheckoutSessionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserStripeCheckoutSessionCreateMutation,
    UserStripeCheckoutSessionCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserStripeCheckoutSessionCreateMutation,
    UserStripeCheckoutSessionCreateMutationVariables
  >(UserStripeCheckoutSessionCreateDocument, options);
}
export type UserStripeCheckoutSessionCreateMutationHookResult = ReturnType<
  typeof useUserStripeCheckoutSessionCreateMutation
>;
export type UserStripeCheckoutSessionCreateMutationResult = Apollo.MutationResult<
  UserStripeCheckoutSessionCreateMutation
>;
export type UserStripeCheckoutSessionCreateMutationOptions = Apollo.BaseMutationOptions<
  UserStripeCheckoutSessionCreateMutation,
  UserStripeCheckoutSessionCreateMutationVariables
>;
export const UpdateSheetDocument = gql`
  mutation updateSheet(
    $id: ID!
    $name: String
    $starred: Boolean!
    $apiKeyEnabled: Boolean!
    $apiKey: String
    $allowedMethods: [String!]
    $allowedOriginsEnabled: Boolean!
    $allowedOrigins: [String!]
    $allowedIpsEnabled: Boolean!
    $allowedIps: [String!]
    $ttl: Int
  ) {
    sheetUpdate(
      input: {
        id: $id
        name: $name
        starred: $starred
        apiKeyEnabled: $apiKeyEnabled
        apiKey: $apiKey
        allowedMethods: $allowedMethods
        allowedOriginsEnabled: $allowedOriginsEnabled
        allowedOrigins: $allowedOrigins
        allowedIpsEnabled: $allowedIpsEnabled
        allowedIps: $allowedIps
        ttl: $ttl
      }
    ) {
      errors {
        field
        message
      }
      sheet {
        id
        name
        starred
        apiKeyEnabled
        apiKey
        allowedMethods
        allowedOriginsEnabled
        allowedOrigins
        allowedIpsEnabled
        allowedIps
        ttl
      }
    }
  }
`;
export type UpdateSheetMutationFn = Apollo.MutationFunction<
  UpdateSheetMutation,
  UpdateSheetMutationVariables
>;
export function useUpdateSheetMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSheetMutation, UpdateSheetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSheetMutation, UpdateSheetMutationVariables>(
    UpdateSheetDocument,
    options
  );
}
export type UpdateSheetMutationHookResult = ReturnType<typeof useUpdateSheetMutation>;
export type UpdateSheetMutationResult = Apollo.MutationResult<UpdateSheetMutation>;
export type UpdateSheetMutationOptions = Apollo.BaseMutationOptions<
  UpdateSheetMutation,
  UpdateSheetMutationVariables
>;
export const ValidateUrlDocument = gql`
  mutation validateURL($url: String!) {
    sheetValidateUrl(input: { url: $url }) {
      isValid
      errors {
        message
      }
    }
  }
`;
export type ValidateUrlMutationFn = Apollo.MutationFunction<
  ValidateUrlMutation,
  ValidateUrlMutationVariables
>;
export function useValidateUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<ValidateUrlMutation, ValidateUrlMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateUrlMutation, ValidateUrlMutationVariables>(
    ValidateUrlDocument,
    options
  );
}
export type ValidateUrlMutationHookResult = ReturnType<typeof useValidateUrlMutation>;
export type ValidateUrlMutationResult = Apollo.MutationResult<ValidateUrlMutation>;
export type ValidateUrlMutationOptions = Apollo.BaseMutationOptions<
  ValidateUrlMutation,
  ValidateUrlMutationVariables
>;
// Generated on 2024-11-05T15:15:40+00:00
